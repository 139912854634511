import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "gainACard",
    "howToClaim",
    "levellingUp",
    "exampleReward",
    "claimedRewards"
  ]

  showNext(event) {
    const showOrder = [
      "gainACard",
      "howToClaim",
      "levellingUp",
      "exampleReward",
      "claimedRewards"
    ]
    const current = showOrder.indexOf(event.target.parentElement.parentElement.parentElement.dataset["referralRewardsTarget"])
    $(eval("this." + `${showOrder[current+1]}Target`)).removeClass("hidden")

    // swap next button with tick
    $(document.getElementById(`button-container-${showOrder[current]}`).childNodes[1]).replaceWith('\
        <svg xmlns="http://www.w3.org/2000/svg" class="-mt-2 h-12 w-12 text-white mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">\
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />\
        </svg>')
  }

  openModal(e) {
    e.preventDefault()

    const id = e.currentTarget.getAttribute('data-referral-rewards-target')
    const element = document.getElementById(id)
    // console.log(id, element)
    $(element).addClass('active')
    $('body').addClass('modal-shown')
  }

  scrollToClaimed() {
    window.animating = true
    $('html, body').animate({ scrollTop: $(document).height() }, 700,
      function () {
        window.animating = false
      }
    )
  }
}
