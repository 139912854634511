import { Controller } from 'stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["createInput", "innerCreateButton", "listItem"]

  select(event) {
    event.preventDefault()

    const styleId = event.target.dataset.styleId
    this.selectListItem(styleId)

    const form = document.getElementById("style_select_form")
    const input = document.getElementById("style_select_style_id")
    input.value = styleId

    // hide preview
    const formElement = document.getElementById('brading-style-wrapper')
    const formController = this.application.getControllerForElementAndIdentifier(formElement, "branding-style")
    formController.hidePreview()

    // submit to reshow preview
    Rails.fire(form, 'submit')
  }

  selectListItem(styleId) {
    this.listItemTargets.forEach((el, i) => {
      let dot = el.querySelector('.dot')
      let selectBtn = el.querySelector('.select-btn')
      let trash = el.querySelector('.trash')

      dot.classList.toggle('hidden', el.dataset.styleId != styleId)
      selectBtn.classList.toggle('hidden', el.dataset.styleId == styleId)
      trash.classList.toggle('hidden', el.dataset.styleId == styleId)
    })
  }

  delete(event) {
    event.preventDefault()

    const clicked = event.target
    const item = clicked.closest('.selector_item')
    const answer = confirm(`Are you sure you wish to delete the ${item.dataset.name} style?`)

    if (answer) {
      Rails.ajax({
        url: item.dataset.deleteUrl,
        type: 'delete',
        success: (data) => {
          item.remove()
          this.renumberItems()
        }
      })
    }
  }

  renumberItems() {
    const items = Array.from(document.getElementsByClassName('selector_item_index'))
    items.forEach((item, index) => item.innerHTML = index + 1)
  }

  showCreateInput(event) {
    event.preventDefault()

    this.innerCreateButtonTarget.classList.add("hidden")
    this.createInputTarget.classList.remove("hidden")
    setTimeout(() => { document.getElementById('branding_new_style').focus(); }, 750);
  }

  hideCreateInput(event) {
    event.preventDefault()

    this.createInputTarget.classList.add("hidden")
    this.innerCreateButtonTarget.classList.remove("hidden")
  }
}
