import { Controller } from 'stimulus'
import moment from "moment"
window.moment = moment
export default class extends Controller {
  static targets = ['modal', 'datePastError', 'dateTooSoonError', 'submitButton']

  openModal(e) {
    e.preventDefault()
    const $clicked = $(e.currentTarget)
    this.$modal = $(this.modalTarget)
    this.$modal.find('#order_id').val($clicked.data('order-id'))
    this.setChosenTimezone($clicked.data('timezone'))

    this.$modal.addClass('active')
    $('body').addClass('modal-shown')

    return true
  }

  escCloseModal(e) {
    if (e.key === 'Escape') this.closeModal(e)
  }

  changeTimezone(e) {
    const $clicked = $(e.currentTarget)
    this.setChosenTimezone($clicked.val())
  }

  setChosenTimezone(val) {
    this.$modal.find('#order_time_zone').val(val)
    this.$modal.find('#timezone_hint').text(val)
  }

  checkDate() {
    let selectedDate = new Date(
      $('#order_event_date_1i').val(),
      $('#order_event_date_2i').val() - 1,
      $('#order_event_date_3i').val(),
      $('#order_event_date_4i').val(),
      $('#order_event_date_5i').val()
    )

    const datePast = moment().isAfter(moment(selectedDate), "day")
    const dateTooSoon = moment().add(48, "hours").isAfter(moment(selectedDate), "hour") && !datePast

    $(this.datePastErrorTarget).toggleClass(
      "hidden",
      !datePast
    )

    $(this.dateTooSoonErrorTarget).toggleClass(
      "hidden",
      !dateTooSoon
    )

    const disableButton = datePast || dateTooSoon
    this.submitButtonTarget.disabled = disableButton
  }

  closeModal(e) {
    e.preventDefault()

    $(this.modalTarget).removeClass('active')
    $('body').removeClass('modal-shown')

    return true
  }
}
