import { Controller } from 'stimulus'
import LazyLoad from 'vanilla-lazyload'

export default class extends Controller {
  static targets = [
    'overviewFrame',
    'infoFormFrame',
    'cardNameInput',
    'cardNumberInput',
    'cardExpiryInput',
    'cardCvcInput',
    'cardSubmit',
    'cardForm',
    'cardErrors',
    'priceOptionsFrame',
    'priceOptionsTable',
    'upgradingPlan',
    'offeringPlan',
    'addCardMessage',
    'paymentWarning',
    'terms',
    'modal'
  ]

  connect() {
    this.setupFrameWatchers()

    this.stripe = Stripe(this.data.get('public-key'))
    this.stripeStyle = JSON.parse(this.data.get('stripe-style'))
    this.currency = this.data.get('currency')
    this.setTeamReadyForUpgrade()

    this.setupStripeElements()

    this.determineButtonValidity()
  }

  setupFrameWatchers() {
    // Watches the email, card and info forms for submission then updates the overview
    let watchTargets = [this.infoFormFrameTarget]
    this.formFramesObserver = new MutationObserver(
      this.framesMutated.bind(this)
    )
    watchTargets.forEach(function (item, index) {
      this.formFramesObserver.observe(item, { childList: true })
    }.bind(this))

    // Watches the overview frame for a reload then runs some initialisation
    this.overviewObserver = new MutationObserver(
      this.overviewMutated.bind(this)
    )
    this.overviewObserver.observe(this.overviewFrameTarget, { childList: true })

    // Watches the price options table to see if the team is ready for a purchase using chosen currrency
    this.priceOptionsFrameObserver = new MutationObserver(
      this.priceOptionsFrameMutated.bind(this)
    )
    this.priceOptionsFrameObserver.observe(this.priceOptionsFrameTarget, {
      childList: true,
    })
  }

  setupStripeElements() {
    const elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://use.typekit.net/wcy2cwh.css',
        },
      ],
    })
    this.cardNumber = elements.create('cardNumber', {
      style: this.stripeStyle,
      showIcon: true,
    })
    this.cardNumber.mount(this.cardNumberInputTarget)
    this.cardNumber.on('change', (event) => this.cardChanged(event))

    this.expiry = elements.create('cardExpiry', { style: this.stripeStyle })
    this.expiry.on('change', (event) => this.cardChanged(event))
    this.expiry.mount(this.cardExpiryInputTarget)

    this.cvc = elements.create('cardCvc', { style: this.stripeStyle })
    this.cvc.mount(this.cardCvcInputTarget)
    this.cvc.on('change', (event) => this.cardChanged(event))
  }

  cardChanged(event) {
    if (event.error) {
      this.cardErrorsTarget.textContent = event.error.message
    } else {
      this.cardErrorsTarget.textContent = ''
    }
  }

  submitUpdatedCard(event) {
    event.preventDefault()
    event.stopPropagation()

    this.disableCardSubmitButton()
    this.cardErrorsTarget.textContent = ''

    if (this.cardNameInputTarget.value.length == 0) {
      this.cardErrorsTarget.textContent = 'Please enter the name on your card'
      this.reEnableCardSubmitButton()
    } else if (!this.currency) {
      this.cardErrorsTarget.textContent =
        'Please select your preferred currency for your plan'
      this.reEnableCardSubmitButton()
    } else {
      this.stripe
        .createToken(this.cardNumber, { currency: this.currency })
        .then((result) => {
          if (result.error) {
            // console.log(result.error)
            this.cardErrorsTarget.textContent = result.error.message
            this.reEnableCardSubmitButton()
          } else {
            this.addHiddenStripeTokenField(result.token)
          }
        })
    }
  }

  disableCardSubmitButton() {
    Rails.disableElement(this.cardSubmitTarget)
  }

  reEnableCardSubmitButton() {
    setTimeout(() => {
      Rails.enableElement(this.cardSubmitTarget)
    }, 300)
  }

  addHiddenStripeTokenField(token) {
    const hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripe_token')
    hiddenInput.setAttribute('value', token.id)

    this.cardFormTarget.appendChild(hiddenInput)
    this.cardFormTarget.submit()
  }

  framesMutated() {
    let src = this.overviewFrameTarget.getAttribute('src')
    this.overviewFrameTarget.setAttribute('src', src)
    this.initLazyLoad()
  }

  overviewMutated() {
    this.initLazyLoad()
  }

  initLazyLoad() {
    let lazyLoadOptions = { elements_selector: '.lazy' }
    window.pageLazyLoad = new LazyLoad(lazyLoadOptions)
  }

  setTeamReadyForUpgrade() {
    if (this.hasPriceOptionsTableTarget) {
      this.teamReadyForUpgrade = !!Number(
        this.priceOptionsTableTarget.dataset.teamReadyForUpgrade
      )
    }
  }

  priceOptionsFrameMutated(mutations) {
    this.setTeamReadyForUpgrade()
  }

  determineButtonValidity() {
    var enabled = true
    if (this.hasTermsTarget) {
      var enabled = this.termsTarget.checked
    }

    $(this.cardSubmitTarget).attr('disabled', !enabled)
  }

  upgrade(event) {
    const planId = event.currentTarget.getAttribute('data-plan-id')
    const planDiscountedPrice = event.currentTarget.getAttribute('data-plan-discounted-price')
    const planFullPrice = event.currentTarget.getAttribute('data-plan-full-price')
    const isAnnual = event.currentTarget.getAttribute('data-annual')

    debugger

    let msg;
    if (planFullPrice == planDiscountedPrice) {
      msg = `You are going to be charged ${planDiscountedPrice}. This will renew ${isAnnual == 'true' ? "annually" : "monthly" } but you can cancel at any time.`
    } else {
      msg = `You are going to be charged ${planDiscountedPrice}. This will renew ${isAnnual == 'true' ? "annually" : "monthly" } at a cost of ${planFullPrice}, but you can cancel at any time.`
    }

    if (!this.teamReadyForUpgrade) {
      event.preventDefault()
      event.stopPropagation()
      // Switch Stripe region
      Rails.ajax({
        url: this.upgradingPlanTarget.dataset.switchCurrencyUrl,
        type: 'patch',
        success: (data) => {
          this.stripe = Stripe(data.public_api_key)
          this.setupStripeElements()
          document.getElementById('billing_tab_card').click()
          this.addCardMessageTarget.classList.remove('hidden')
          //

          $(this.paymentWarningTarget).text(msg).show()
          this.cardSubmitTarget.value = 'Add Card and Upgrade'
          $(this.cardFormTarget).append(
            `<input type="hidden" name="plan_id_intent" value="${planId}" />`
          )
        },
      })
    } else {
      if (window.confirm(msg)) {
        this.offeringPlanTarget.classList.add('hidden')
        this.upgradingPlanTarget.classList.remove('hidden')
      } else {
        event.preventDefault()
      }
    }
  }

  confirmCancel(event) {
    if(window.confirm("Are you sure you want to cancel?")) {
      // let form submit
    } else {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  showConfirmCancelModal(event) {
    $(this.modalTarget).addClass('active')
    $('body').addClass('modal-shown')
  }

  closeModal() {
    $(this.modalTarget).removeClass('active')
    $('body').removeClass('modal-shown')
  }
}
