import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'youButton',
    'teamButton',
    'events',
    'participants',
    'hours',
    'savings',
  ]
  static classes = ['active', 'inactive']

  initialize() {
    this.mode = 'you'
  }

  changeState(e) {
    this.mode = $(e.currentTarget).data('type')
    this.setButtonState()
    this.setData()
  }

  setButtonState() {
    if (this.mode === 'team') {
      $(this.teamButtonTarget).removeClass(this.inactiveClass)
      $(this.teamButtonTarget).addClass(this.activeClass)

      $(this.youButtonTarget).removeClass(this.activeClass)
      $(this.youButtonTarget).addClass(this.inactiveClass)
    } else {
      $(this.teamButtonTarget).removeClass(this.activeClass)
      $(this.teamButtonTarget).addClass(this.inactiveClass)

      $(this.youButtonTarget).removeClass(this.inactiveClass)
      $(this.youButtonTarget).addClass(this.activeClass)
    }
  }

  setData() {
    if (this.mode === 'team') {
      this.eventsTarget.textContent = this.eventsTarget.dataset.team
      this.participantsTarget.textContent = this.participantsTarget.dataset.team
      this.hoursTarget.textContent = this.hoursTarget.dataset.team
      this.savingsTarget.textContent = this.savingsTarget.dataset.team
    } else {
      this.eventsTarget.textContent = this.eventsTarget.dataset.user
      this.participantsTarget.textContent = this.participantsTarget.dataset.user
      this.hoursTarget.textContent = this.hoursTarget.dataset.user
      this.savingsTarget.textContent = this.savingsTarget.dataset.user
    }
  }
}
