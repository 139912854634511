import { Controller } from 'stimulus'
import 'slick-carousel'

export default class extends Controller {
  static targets = ['slider']

  connect() {
    $(this.sliderTarget).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 7000,
    })
  }
}
