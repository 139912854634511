import { Controller } from 'stimulus'
import LazyLoad from 'vanilla-lazyload'

export default class extends Controller {
  static targets = ["formFrame"]

  connect() {
    this.formObserver = new MutationObserver(this.formFrameMutated.bind(this))
    this.formObserver.observe(this.formFrameTarget, { childList: true })
  }

  formFrameMutated() { // load in the newly uploaded avatar image
    let lazyLoadOptions = { elements_selector: '.lazy' }
    window.pageLazyLoad = new LazyLoad(lazyLoadOptions)
  }
}
