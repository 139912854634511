import { Controller } from 'stimulus'
import Rails from "@rails/ujs"
// import Vibrant from 'node-vibrant'
import ColorUtils from '../../src/javascripts/colour-utils.js'

export default class extends Controller {
  static targets = [
    "saving",
    "preview",
    "previewLoading",
    "mobilePreviewHeader",
    "mobilePreviewBody",
    "mobilePreviewBurgerMenu",
    "mobilePreviewLogo",
    "inlineName",
    "nameInput",
    "primaryColourInput",
    "primaryColourBlock",
    "secondaryColourInput",
    "secondaryColourBlock"
  ]

  static values = {
    remoteLoad: Boolean,
    name: String,
    primaryColour: String,
    secondaryColour: String,
    logo: String
  }

  connect() {
    this.nameValueOriginal = this.nameValue
    this.primaryColourValueOriginal = this.primaryColourValue
    this.secondaryColourValueOriginal = this.secondaryColourValue

    this.updateColourBlocks()
    if (this.remoteLoadValue === true) document.dispatchEvent(new Event('uppy:reinit'))
  }

  configureForm() {
    this.updateColourBlocks()
    this.showColourBlocks()
    this.updateMobilePreview()
  }

  updateMobilePreview() {
    this.updateMobilePreviewHeader()
    this.updateMobilePreviewBody()
    this.updateMobilePreviewBurgerMenu()
    this.showPreview()
  }

  showPreview() {
    this.previewLoadingTarget.classList.add("hidden")
    this.previewTarget.classList.remove("hidden")
  }

  hidePreview() {
    this.previewTarget.classList.add("hidden")
    this.previewLoadingTarget.classList.remove("hidden")
  }

  logoImageLoaded(event) {
    let imgSrc = event.target.getAttribute('src')
    this.mobilePreviewLogoTarget.setAttribute('src', imgSrc)
    // this.scanImageForProminentColours(imgSrc)
  }

  updateName(event) {
    this.nameValue = event.target.value
    this.updateInlineName()
  }

  updateInlineName() {
    this.inlineNameTarget.textContent = this.nameValue
  }

  inputPrimaryColour() {
    let value = this.primaryColourBlockTarget.value
    if (value && value.length > 0 && value.substring(0, 1) !== '#') {
      value = "#".concat(value)
    }
    value = value.substring(0, 7).toUpperCase()

    this.primaryColourBlockTarget.value = value
    if (value.length == 7) {
      this.primaryColourValue = value
      this.primaryColourInputTarget.value = value
      this.updateColourBlocks()
      this.updateMobilePreview()
    }
  }

  inputSecondaryColour() {
    let value = this.secondaryColourBlockTarget.value
    if (value && value.length > 0 && value.substring(0, 1) !== '#') {
      value = "#".concat(value)
    }
    value = value.substring(0, 7).toUpperCase()

    this.secondaryColourBlockTarget.value = value
    if (value.length == 7) {
      this.secondaryColourValue = value
      this.secondaryColourInputTarget.value = value
      this.updateColourBlocks()
      this.updateMobilePreview()
    }
  }

  updatePrimaryColour(event) {
    this.primaryColourValue = event.target.value
    this.updateColourBlocks()
    this.updateMobilePreview()
  }

  updateColourBlocks() {
    this.updatePrimaryColourBlock()
    this.updateSecondaryColourBlock()
  }

  showColourBlocks() {
    this.primaryColourBlockTarget.classList.remove('invisible')
    this.secondaryColourBlockTarget.classList.remove('invisible')
  }

  updatePrimaryColourBlock() {
    this.primaryColourBlockTarget.value = this.primaryColourValue.toUpperCase()
    this.primaryColourBlockTarget.style.backgroundColor = this.primaryColourValue
    this.primaryColourBlockTarget.style.color = ColorUtils.contrastingColor(this.primaryColourValue)
  }

  updateSecondaryColourBlock() {
    this.secondaryColourBlockTarget.value = this.secondaryColourValue.toUpperCase()
    this.secondaryColourBlockTarget.style.backgroundColor = this.secondaryColourValue
    this.secondaryColourBlockTarget.style.color = ColorUtils.contrastingColor(this.secondaryColourValue)
  }

  updateMobilePreviewHeader() {
    this.mobilePreviewHeaderTarget.style.backgroundColor = this.primaryColourValue
  }

  updateMobilePreviewBody() {
    this.mobilePreviewBodyTarget.style.backgroundColor = this.secondaryColourValue
  }

  updateMobilePreviewBurgerMenu() {
    this.mobilePreviewBurgerMenuTarget.setAttribute("stroke", ColorUtils.contrastingColor(this.primaryColourValue))
  }

  updateSecondaryColour(event) {
    this.secondaryColourValue = event.target.value
    this.updateColourBlocks()
    this.updateMobilePreview()
  }

  scanImageForProminentColours(imgSrc) {
    // if (typeof imgSrc === "undefined") return

    // Vibrant.from(imgSrc).getPalette((err, palette) => {
    //   if (palette && palette.Vibrant) {
    //     this.primaryColourValue = palette.Vibrant.getHex()
    //   }
    //   if (palette && palette.DarkVibrant) {
    //     this.secondaryColourValue = palette.DarkVibrant.getHex()
    //   }
    //   this.updateColourBlocks()
    //   this.updateMobilePreview()
    // })
  }

  resetStyle(event) {
    event.preventDefault()

    if (!confirm("Are you sure you want to reset this style?")) return

    this.nameValue = this.nameValueOriginal
    this.nameInputTarget.value = this.nameValue
    this.updateInlineName()

    this.primaryColourValue = this.primaryColourValueOriginal
    this.secondaryColourValue = this.secondaryColourValueOriginal
    this.updateColourBlocks()
    this.updateMobilePreview()
  }

  createStyleInModal(event) {
    const modalElement = document.getElementById('branding-style-modal')
    const modalController = this.application.getControllerForElementAndIdentifier(modalElement, "branding-style-modal")
    modalController.showCreateInput(event)
  }

  hideCreateStyleInModal(event) {
    const modalElement = document.getElementById('branding-style-modal')
    const modalController = this.application.getControllerForElementAndIdentifier(modalElement, "branding-style-modal")
    modalController.hideCreateInput(event)
  }
}
