import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "teamName",
    "teamNameError",
    "organisationName",
    "organisationNameError"
  ]
  static values = { 'minPassword': Number }

  submitForm(event) {
    if (this.hasTeamNameTarget) {
      this.checkValidTeamName(event)
    }

    if (this.hasOrganisationNameTarget) {
      this.checkValidOrganisationName(event)
    }
  }

  checkValidTeamName(event) {
    let submittedTeamName = this.teamNameTarget.value
    this.teamNameErrorTarget.innerHTML = ""
    this.teamNameErrorTarget.classList.add("hidden")

    if (submittedTeamName.length == 0) {
      event.preventDefault()

      this.teamNameErrorTarget.innerHTML = "Please enter a name for your team"
      this.teamNameErrorTarget.classList.remove("hidden")
    }
  }

  checkValidOrganisationName(event) {
    let submittedOrganisationName = this.organisationNameTarget.value
    this.organisationNameErrorTarget.innerHTML = ""
    this.organisationNameErrorTarget.classList.add("hidden")

    if (submittedOrganisationName.length == 0) {
      event.preventDefault()

      this.organisationNameErrorTarget.innerHTML = "Please enter a name for your organisation"
      this.organisationNameErrorTarget.classList.remove("hidden")
    }
  }
}