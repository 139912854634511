import { Controller } from 'stimulus'
import 'slick-carousel'

export default class extends Controller {
  static targets = ['slider', 'prev', 'next']

  connect() {
    $(this.sliderTarget).slick({
      prevArrow: this.prevTarget,
      nextArrow: this.nextTarget,
      dots: true,
      dotsClass: "bitesize-dots",
      infinite: false,
      accessibility: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    })
  }
}
