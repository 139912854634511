import { Controller } from 'stimulus'
import LazyLoad from 'vanilla-lazyload'

export default class extends Controller {
  static targets = ['teamDetailsFrame', 'editTeamDetailsModal']

  connect() {
    setTimeout(() => {
      this.initLazyLoad()
    }, 750)

    this.editTeamDetailsObserver = new MutationObserver(
      this.teamDetailsMutated.bind(this)
    )
    this.editTeamDetailsObserver.observe(this.editTeamDetailsModalTarget, {
      childList: true,
      subtree: true,
    })
  }

  teamDetailsMutated() {
    if ($(this.editTeamDetailsModalTarget).find('.success').length) {
      let src = this.teamDetailsFrameTarget.getAttribute('src')
      this.teamDetailsFrameTarget.setAttribute('src', src)
    }
    setTimeout(() => {
      this.initLazyLoad()
    }, 1000)
  }

  initLazyLoad() {
    let lazyLoadOptions = { elements_selector: '.lazy' }
    window.pageLazyLoad = new LazyLoad(lazyLoadOptions)
  }

  openEditTeamDetailsModal(event) {
    event.preventDefault()
    event.stopPropagation()

    $(this.editTeamDetailsModalTarget).addClass('active')
    $('body').addClass('modal-shown')

    return false
  }

  closeEditTeamDetailsModal(event) {
    let $clicked = $(event.target)
    if (
      $clicked.hasClass('open-modal') ||
      $clicked.closest('.uppy-Root').length ||
      (!!$($clicked).closest('.modal-body').length &&
        !$($clicked).hasClass('close-modal'))
    )
      return

    $(this.editTeamDetailsModalTarget).find(".flash").remove()
    $(this.editTeamDetailsModalTarget).removeClass('active')
    $('body').removeClass('modal-shown')
  }
}
